import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./HomePage";
import PrivacyPolicy from "./PrivacyPolicy"; // Importe o novo componente
import StoreFallbackPage from "./StoreFallbackPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
        <Route path="/store/view/:id" element={<StoreFallbackPage />} />
      </Routes>
    </Router>
  );
}

export default App;
