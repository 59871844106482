
const StoreFallbackPage = () => {

  return (
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <h1>Baixe o nosso aplicativo</h1>
      <p>Para visualizar esta loja, baixe o nosso app:</p>
      <div style={{ marginTop: "20px" }}>
        {/* Botão Google Play */}
        <a
          href="https://play.google.com/store/apps/details?id=next.com.meachou"
          style={{
            display: "inline-block",
            margin: "0 10px",
            padding: "10px 20px",
            backgroundColor: "#A4C639",
            color: "#fff",
            textDecoration: "none",
            borderRadius: "5px",
          }}
        >
          Google Play
        </a>
        {/* Botão App Store (caso tenha o link) */}
        {/* 
        <a
          href="https://apps.apple.com/...."
          style={{
            display: "inline-block",
            margin: "0 10px",
            padding: "10px 20px",
            backgroundColor: "#333",
            color: "#fff",
            textDecoration: "none",
            borderRadius: "5px",
          }}
        >
          App Store
        </a>
        */}
      </div>
    </div>
  );
};

export default StoreFallbackPage;
